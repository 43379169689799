@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	position: relative;
	margin-bottom: 25rem;
	padding-left: 16rem;

	@include under-md() {
		margin-bottom: 13rem;
		padding-left: 5rem;
	}
}

.title {
	margin-bottom: 4rem;
	@include f1-500();
	font-size: 8.8rem;
	line-height: 9.6/8.8+em;
	color: $minsk;

	@include under-md() {
		margin-bottom: 2rem;
		padding-right: 1.6rem;
		font-size: 2.8rem;
		line-height: 3/2.8+em;
	}
}

.content {

	@include under-md() {
		position: relative;
	}
}

.aside {

	@include under-md() {
		left: -5rem;
	}
}

.image {
	position: relative;
	max-width: 100%;
	overflow: hidden;
	line-height: 0;

	@include under-md() {
		height: 58rem;
	}

	&P {
		position: absolute;
		z-index: 2;
		bottom: 2rem;
		left: 3.6rem;
		pointer-events: none;
		@include f1-900i();
		font-size: 20rem;
		line-height: 1;
		color: #fff;
		white-space: nowrap;

		@include under-md() {
			transform-origin: 0 100%;
			transform: translateX(100%) rotate(-90deg);
			left: auto;
			right: 2rem;
			bottom: 1.6rem;
			font-size: 6rem;
		}
	}

	img,
	canvas {
		position: static !important;
		transform: none !important;
		width: 100%;
		height: 100%;
		object-fit: cover;
		cursor: default !important;
	}
}