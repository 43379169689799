@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	position: relative;
	margin-bottom: 25rem;
	padding-left: 16rem;
	overflow: hidden;

	@include under-md() {
		margin-bottom: 13rem;
		padding: 0 1.6rem;
	}
}

.aside {

	@include under-md() {
		display: none;
	}
}

.title {
	margin-bottom: 6rem;

	@include under-md() {
		margin-bottom: 3rem;
	}
}

.p {
	@include f1-500();
	font-size: 5.5rem;
	line-height: 7/5.5+em;
	color: $minsk;

	@include under-md() {
		font-size: 2rem;
		line-height: 1.5;
	}

	span {

		&:not(:last-child) {
			margin-right: 4rem;

			@include under-md() {
				margin-right: 1.8rem;
			}
		}
	}
}