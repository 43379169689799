@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	margin-bottom: 25rem;
	padding-left: 8rem;
	overflow: hidden;

	@include under-md() {
		margin-bottom: 12rem;
		padding: 0 1.6rem;
	}
}

.title {
	margin-bottom: 7rem;
	padding-left: 8rem;

	@include under-md() {
		margin-bottom: 4rem;
		padding-left: 0;
	}
}

.list {
	margin: 0 -10rem 0 -4.5rem;

	@include under-md() {
		margin: 0 -.8rem;
	}
}

.link {
	padding: 0 4.5rem;
	outline: none;

	img {
		max-width: 100%;
	}

	@include under-md() {
		padding: 0 .8rem;
	}
}