@import "src/scss/variables";
@import "src/scss/mixins";

.aside {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;

	@include under-md() {
		align-items: center;
		width: 5rem;
	}

	&:global(._arrow) {

		&:before {
			content: '';
			flex: 1 1 auto;
			margin-top: 12rem;
			margin-left: 4.8rem;
			width: .2rem;
			background: $razzle-dazzle-rose;
		}

		&:after {
			content: '';
			position: absolute;
			left: 4.5rem;
			bottom: 0;
			width: .6rem;
			height: .6rem;
			border-left: .2rem solid $razzle-dazzle-rose;
			border-bottom: .2rem solid $razzle-dazzle-rose;
			transform: rotate(-45deg);
		}

		@include under-md() {

			&:before {
				margin-top: 10.8rem;
				margin-left: .3rem;
			}

			&:after {
				left: 2.3rem;
			}
		}
	}

	&Text {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		margin-bottom: 2rem;
		margin-left: .3rem;
		transform: rotate(-90deg) translateX(-50%);
		@include f1-500();
		font-size: 1.6rem;
		line-height: 24/16+em;
		color: $razzle-dazzle-rose;
		white-space: nowrap;

		@include under-md() {
			margin: -1rem 0 0 -1.8rem;
		}
	}
}