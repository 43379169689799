@import "src/scss/variables";
@import "src/scss/mixins";

.container {

}

.ducks {
	height: 4.1rem;
	background: transparent url("~img/ticker/duck.png") repeat-x left top;
}

.text {
	display: flex;
	align-items: center;
	height: 4rem;
	background: $minsk;
	overflow: hidden;
	@include f1-500();
	font-size: 1.6rem;
	line-height: 1.5;
	color: $screaming-green;
	white-space: nowrap;
}