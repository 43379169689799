@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	position: relative;
}

.aside {

	@include under-md() {
		display: none;
	}
}

.row {
	margin-bottom: 11rem;
	padding-left: 16rem;
	padding-right: 4rem;

	@include under-md() {
		margin-bottom: 6rem;
		padding: 0 1.6rem;
	}
}

.title {
	margin-bottom: 3rem;
	@include f1-500();
	font-size: 9rem;
	line-height: 1.15;
	color: $minsk;

	@include under-md() {
		margin-bottom: 1rem;
		font-size: 2.8rem;
	}
}

.p {
	@include f1-500();
	font-size: 5.5rem;
	line-height: 7/5.5+em;
	color: $minsk;
	text-decoration: none;

	@include under-md() {
		font-size: 2rem;
		line-height: 1.5;
	}
}

.ticker {
	margin-top: 12rem;
}