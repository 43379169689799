@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	position: relative;
	margin-bottom: 10rem;
	width: 100%;
	line-height: 0;

	@include under-md() {
		margin-bottom: 6rem;
		height: 45rem;
	}

	img,
	canvas {
		position: static !important;
		transform: none !important;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.title {
	position: absolute;
	bottom: 2rem;
	left: 16rem;
	right: 1.6rem;
	pointer-events: none;
	@include f1-900i();
	font-size: 14rem;
	line-height: 20/14+em;
	color: #fff;
	text-decoration: none;

	@include under-md() {
		left: 1.6rem;
		bottom: 1rem;
		font-size: 6rem;
		line-height: 1;
	}
}