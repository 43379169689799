//colors
$minsk: #3b2f98;

$razzle-dazzle-rose: #f83ce0;

$screaming-green: #75fa72;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;