@font-face {
	font-family: 'Cera Pro Medium';
	src:
		url('fonts/cerapro-medium.woff2') format('woff2'),
		url('fonts/cerapro-medium.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Cera Pro Black Italic';
	src:
		url('fonts/cerapro-blackitalic.woff2') format('woff2'),
		url('fonts/cerapro-blackitalic.woff') format('woff');
	font-weight: 400;
}