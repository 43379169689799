@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 4rem;

	svg {
		width: 7.3rem;
		height: auto;
	}

	@include under-md() {
		padding: 2rem 1.6rem;
	}
}

.logo {
	@include f1-500();
	font-size: 2.64rem;
	line-height: 1.15;
	text-decoration: none;
	color: $minsk;
}

.inTouch {
	position: relative;
	@extend %button;
	cursor: pointer;
	line-height: 0;

	&:hover {

		.duck {
			transform: scale(1, 1);
		}
	}

	img,
	svg {
		pointer-events: none;
	}
}

.duck {
	position: absolute;
	top: 1.8rem;
	left: 2.1rem;
	transform: scale(0, 0);
	transition: transform .25s;

	@include under-md() {
		display: none;
	}
}